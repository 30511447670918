@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    padding: 0;
    margin: 0;
    font-family:Arial, Helvetica, sans-serif;
    // background: #2e2d38;
    background: #eff3f6;
}
* { 
    box-sizing: border-box;
}
.main {
    &__menu {
        width: 180px;
    }
    &__content {
        width: calc(100% - 180px);
    }
}

input[type="number"]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

// .container {
//     display: flex;
//     align-items: flex-start;
//     width: 100%;
//     padding: 1vw 2vw;
//     &__left {
//         width: calc(100% - 400px);
//         padding-right: 3vw;
//     }
//     &__right {
//         width: 400px;
//         background: #f1f1f1;
//         border-radius: 20px;
//         padding: 10px 20px;
//         position: sticky;
//         top: 20px;
//         table {
//             width: 100%;
//         }
//         td, th {
//             text-align: center;
//             padding: 10px;
//             background: #fff;
//         }
//     }
// }